import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
type four={
    name: string
    isChecked:boolean
}
type checkBoxInputs={
    errorMessage:string,
    inputs:{
        isChecked:boolean,
        inputValue:string,
        isRequired:boolean
    }[]}
type sixData = {
    value: string;
    label: string;
}[]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: any;
    one:checkBoxInputs
    two:checkBoxInputs
    three: {
        threeErrorMessage: string,
        inputs: {
            heading: string,
            value: string,
            isRequired: boolean
        }[],
    }
    four: {
        fourErrorMessage: string,
        checkBoxes: four[]
    };
    five: {
        fiveErrorMessage: string;
        regions: {
            name: string;
            isChecked: boolean;
        }[];
    },
    six: {
        sixErrorMessage: string;
        value: string
    }
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class SurveyFormController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiGetDataCallId: string = "";
    sixData: sixData = [
        {
            value: "ExistingTruck",
            label: configJSON.existingTruck
        },
        {
            value: "NewTruck",
            label: configJSON.newTruck
        }
    ]
    months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",] 
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            one: {
                errorMessage: "",
                inputs: Array.from(Array(3)).map(() => ({
                    isChecked: false,
                    inputValue: "",
                    isRequired: false
                }))
            },
            two: {
                errorMessage: "",
                inputs: Array.from(Array(3)).map(() => ({
                    isChecked: false,
                    inputValue: "",
                    isRequired: false
                }))
            },
            three: {
                threeErrorMessage: "",
                inputs: [
                    {
                        heading: "Normal Season",
                        value: "",
                        isRequired: false
                    },
                    {
                        heading: "High Season",
                        value: "",
                        isRequired: false
                    }]
            },
            four: {
                fourErrorMessage: "",
                checkBoxes: this.months.map((month) => ({
                    name: month,
                    isChecked: false
                }))
            },
            five: {
                fiveErrorMessage: "",
                regions: [{
                    name: "United States",
                    isChecked: false
                }, {
                    name: "Mexico",
                    isChecked: false
                }]
            },
            six: {
                sixErrorMessage: "",
                value: ""
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    handleOneTwoCheckBox = (checkedValue: boolean, index: number,isOne:boolean ) => {
        if(isOne){
            const newOne = { ...this.state.one }
            newOne.inputs[index].isChecked = checkedValue
            newOne.inputs[index].inputValue = checkedValue ? newOne.inputs[index].inputValue : ""
            this.setState({ one: newOne })
        }else{
            const newTwo= { ...this.state.two }
            newTwo.inputs[index].isChecked = checkedValue
            newTwo.inputs[index].inputValue = checkedValue ? newTwo.inputs[index].inputValue : ""
            this.setState({ two: newTwo })
        }
    }
    handleOneTwoOnChange = (value: string, index: number, isOne: boolean) => {
        if (isOne) {
            const newOne = { ...this.state.one }
            newOne.inputs[index].inputValue = value
            newOne.inputs[index].isRequired = !Boolean(newOne.inputs[index].inputValue)
            newOne.errorMessage = newOne.inputs.some((input) => input.isRequired === true) ? configJSON.requiredField : ""
            this.setState({ one: newOne })
        } else {
            const newTwo = { ...this.state.two }
            newTwo.inputs[index].inputValue = value
            newTwo.inputs[index].isRequired = !Boolean(newTwo.inputs[index].inputValue)
            newTwo.errorMessage = newTwo.inputs.some((input) => input.isRequired === true) ? configJSON.requiredField : ""
            this.setState({ two: newTwo })
        }
    }
    handleThreeOnChange=(value:string,index:number)=>{
        const newThree={...this.state.three}
        newThree.inputs[index].value=value
        newThree.inputs[index].isRequired=!Boolean(  newThree.inputs[index].value)
        newThree.threeErrorMessage=  newThree.inputs.some((input) => input.isRequired === true)?configJSON.requiredField:""
        this.setState({three:newThree})
    }
    handleFourCheckBox=(checkedValue:boolean,index:number)=>{
        const checkBoxes={...this.state.four}
        checkBoxes.checkBoxes[index].isChecked=checkedValue
        checkBoxes.fourErrorMessage=checkBoxes.checkBoxes.every((input) => input.isChecked === false)?configJSON.requiredField:""
        this.setState({four:checkBoxes})
    }
    handleFiveCheckBox=(checkedValue:boolean,index:number)=>{
        const checkBoxes={...this.state.five}
        checkBoxes.regions[index].isChecked= checkedValue
        checkBoxes.fiveErrorMessage=checkBoxes.regions.every((input) => input.isChecked === false)?configJSON.requiredField:""
        this.setState({five:checkBoxes})
    }
    handleSixRadio=(event:React.ChangeEvent<HTMLInputElement>)=>{
        this.setState((prev)=>({six:{...prev.six,value:event.target.value,sixErrorMessage:""}}))
    }
    handleOnSubmitValidations=()=>{
        const newOne = { ...this.state.one }
        newOne.inputs.forEach((input,index)=>( 
            newOne.inputs[index].isRequired=input.isChecked&&input.inputValue==""
        ))
        newOne.errorMessage=newOne.inputs.every((input)=>input.inputValue==="")?configJSON.requiredField:""
        const newTwo = { ...this.state.two }
        newTwo.errorMessage=newTwo.inputs.every((input)=>input.inputValue==="")?configJSON.requiredField:""
        const newThree={...this.state.three}
        newThree.inputs.forEach((input,index)=>(
            newThree.inputs[index].isRequired=!Boolean(input.value)
        ))
        newThree.threeErrorMessage=newThree.inputs.some((input) => input.isRequired === true)?configJSON.requiredField:""
        const newFour={...this.state.four}
        newFour.fourErrorMessage=newFour.checkBoxes.every((input) => input.isChecked === false)?configJSON.requiredField:""
        const fiveCheckBoxes={...this.state.five}
        fiveCheckBoxes.fiveErrorMessage=fiveCheckBoxes.regions.every((input) => input.isChecked === false)?configJSON.requiredField:""
        const sixRadio= {...this.state.six}
        sixRadio.sixErrorMessage=!this.state.six.value?configJSON.requiredField:""
        this.setState({one:newOne,two:newTwo,three:newThree,four:newFour,five:fiveCheckBoxes,six:sixRadio})
        const errorMessages=  [newOne.errorMessage,newTwo.errorMessage,newThree.threeErrorMessage,newFour.fourErrorMessage,fiveCheckBoxes.fiveErrorMessage,sixRadio.sixErrorMessage]
        return errorMessages.every((message)=>message==="")
    }    
    handleOnSubmit= async(event:React.ChangeEvent<HTMLInputElement>)=>{
        event.preventDefault() 
       if(this.handleOnSubmitValidations()){
        if(await getStorageData("quoteId")){
            this.handleNavigate("CreateClientExistingTruckForm")
        } else{
            this.handleNavigate("CreateClientNewTruckForm")
        }
       } 
    }
   handleNavigate = (pathUrl:string) => {
       const message: Message = new Message(getName(MessageEnum.NavigationMessage))
       message.addData(getName(MessageEnum.NavigationTargetMessage), pathUrl);
       message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
       this.send(message);
   }

    // Customizable Area End

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        // Customizable Area End
    }



    // Customizable Area End
}
