// Customizable Area Start
import React from "react";

import {
    Box,
    TextField,
    Container,
    Typography,
    FormGroup,
    FormControlLabel,
    Radio,
    createTheme,
    ThemeProvider,
    CssBaseline,
    FormHelperText,
    RadioGroup,
    Button
} from "@mui/material";
import {
    checkedBox,
    unCheckedBox
} from "./assets"
import { styled } from "@mui/styles";
import { Formik, FormikTouched } from "formik";
import * as Yup from "yup";
import Checkbox from '@mui/material/Checkbox';
import Navbar from "../../../../packages/components/src/NavBar.web";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import SurveyFormController, {
    configJSON,
    Props
} from "./SurveyFormController.web";
const theme = createTheme({
    typography: {
      fontFamily: "Quicksand"
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: '#E7E5E4',
                    borderRadius: "8px",
                    borderColor: 'transparent',
                    borderWidth: "0px",
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'transparent',
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'red',
                    },
                    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
                        backgroundColor: "#F5F5F4",
                        borderRadius: "8px",
                        borderColor: 'red',
                        borderWidth: "0px",
                    },
                    "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline": {
                        borderColor: 'transparent',
                    }
                },
            },
        },
    }
  })

export default class SurveyForm extends SurveyFormController {
    constructor(props: Props) {
        super(props);
    }



    render() {
        const {
            one,
            two,
            three,
            four,
            five,
            six
        } = this.state
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navbar navigation={this.props.navigation} id="" />
                <NavigationMenu
                    navigation={this.props.navigation}
                    id=""
                />
                <Container>
                    <ClientDetailBox>
                        <Box className="custom-text-field">
                            <Typography data-test-id="heading" sx={styles.heading}>{configJSON.surveyHeading}</Typography>
                        </Box>
                        <Box
                            data-test-id="form"
                            component={"form"}
                            onSubmit={this.handleOnSubmit}
                            sx={styles.questionMainBox}
                        >
                            <Box >
                                <Box sx={styles.question} >
                                    <Box component={"p"} > {configJSON.firstQuestion.slice(0, 3)} </Box>
                                    <Box component={"p"}>{configJSON.firstQuestion.slice(3)}
                                        <Box component={"span"}>{configJSON.normalSeason}</Box>
                                        {configJSON.inHours}
                                    </Box>
                                </Box>
                                <Box className="custom-text-field">
                                    <FormHelperText data-test-id="one-error-message" sx={styles.helperText}>
                                        {one.errorMessage}
                                    </FormHelperText>
                                    {one.inputs.map((option, index) => (
                                        <Box key={index} style={styles.firstInputBox}>
                                            <Checkbox
                                                data-test-id="one-checkBox"
                                                sx={styles.checkBox}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOneTwoCheckBox(event.target.checked, index, true)}
                                                checked={option.isChecked}
                                                checkedIcon={<Box component={"img"} src={checkedBox} />}
                                                icon={<Box component={"img"} src={unCheckedBox} />}
                                            />
                                            <Typography sx={styles.shiftText(option.isChecked)}>{configJSON.shift} {index + 1}</Typography>
                                            <TextField
                                                autoComplete="off"
                                                disabled={!option.isChecked}
                                                data-test-id="one-input"
                                                fullWidth
                                                placeholder="1 to 8 hours"
                                                value={option.inputValue}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOneTwoOnChange(event.target.value, index, true)}
                                                variant="outlined"
                                                error={option.isChecked && option.isRequired}
                                            />
                                        </Box>))}
                                </Box>
                                <Box component={"hr"} style={styles.hrBox} />
                            </Box>
                            <Box>
                                <Box sx={styles.question} >
                                    <Box component={"p"} >{configJSON.secondQuestion.slice(0, 3)}</Box>
                                    <Box component={"p"}>{configJSON.secondQuestion.slice(3)}
                                        <Box component={"span"}>{configJSON.hightSeason}</Box>
                                        {configJSON.inHours}
                                    </Box>
                                </Box>
                                <Box className="custom-text-field">
                                    <FormHelperText sx={styles.helperText} data-test-id="two-error-message">
                                        {two.errorMessage}
                                    </FormHelperText>
                                    {two.inputs.map((option, index) => (
                                        <Box key={index} style={styles.firstInputBox}>
                                            <Checkbox
                                                data-test-id="two-checkBox"
                                                sx={styles.checkBox}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOneTwoCheckBox(event.target.checked, index, false)}
                                                checked={option.isChecked}
                                                checkedIcon={<Box component={"img"} src={checkedBox} />}
                                                icon={<Box component={"img"} src={unCheckedBox} />}
                                            />
                                            <Typography sx={styles.shiftText(option.isChecked)}>{configJSON.shift} {index + 1}</Typography>
                                            <TextField
                                                disabled={!option.isChecked}
                                                data-test-id="two-input"
                                                fullWidth
                                                autoComplete="off"
                                                placeholder="1 to 8 hours"
                                                value={option.inputValue}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleOneTwoOnChange(event.target.value, index, false)}
                                                variant="outlined"
                                                error={option.isChecked && option.isRequired}
                                            />
                                        </Box>))}
                                </Box>
                                <Box component={"hr"} style={styles.hrBox} />
                            </Box>
                            <Box>
                                <Box sx={styles.question} >
                                    <Box component={"p"} > {configJSON.thirdQuestion.slice(0, 3)}</Box>
                                    <Box component={"p"}>{configJSON.thirdQuestion.slice(3)}</Box>
                                </Box>
                                <Box className="custom-text-field">
                                    <FormHelperText sx={styles.helperText} data-test-id="three-error-message">
                                        {three.threeErrorMessage}
                                    </FormHelperText>
                                    {three.inputs.map((input, index) => (
                                        <Box key={index} sx={styles.seasonMainBox}>
                                            <Box sx={styles.passwordTypo2}>
                                                {input.heading}
                                            </Box>
                                            <TextField
                                                data-test-id="three-input"
                                                placeholder="Answer"
                                                autoComplete="off"
                                                value={input.value}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleThreeOnChange(event.target.value, index)}
                                                variant="outlined"
                                                error={input.isRequired}
                                            />
                                        </Box>
                                    ))
                                    }
                                </Box>
                                <Box component={"hr"} style={styles.hrBox} />
                            </Box>
                            <Box>
                                <Box sx={styles.question} >
                                    <Box component={"p"} > {configJSON.fourthQuestion.slice(0, 3)}</Box>
                                    <Box component={"p"}>{configJSON.fourthQuestion.slice(3)}</Box>
                                </Box>
                                <Box className="custom-text-field" data-test-id="fourth-error-message">
                                    <FormHelperText sx={styles.helperText}>
                                        {four.fourErrorMessage}
                                    </FormHelperText>
                                    <CheckBoxMainBox>
                                        {four.checkBoxes.map((checkBox, index) => (
                                            <Box sx={styles.checkBoxFour} key={checkBox.name}>
                                                <Checkbox
                                                    data-test-id="fourth-checked-box"
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFourCheckBox(event.target.checked, index)}
                                                    checked={checkBox.isChecked}
                                                    icon={<Box component={"img"} src={unCheckedBox} />}
                                                    checkedIcon={<Box component={"img"} src={checkedBox} />}
                                                />
                                                <Typography sx={styles.shiftText(checkBox.isChecked)}>
                                                    {checkBox.name}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </CheckBoxMainBox>
                                </Box>
                                <Box component={"hr"} style={styles.hrBox} />
                            </Box>
                            <Box>
                                <Box sx={styles.question} >
                                    <Box component={"p"} > {configJSON.fifthQuestion.slice(0, 3)}</Box>
                                    <Box component={"p"}>{configJSON.fifthQuestion.slice(3)}</Box>
                                </Box>
                                <Box className="custom-text-field">
                                    <FormHelperText sx={styles.helperText} data-test-id="fifth-error-message">
                                        {five.fiveErrorMessage}
                                    </FormHelperText>
                                    <CheckBoxMainBox>
                                        {five.regions.map((checkBox, index) => (
                                            <Box key={checkBox.name} sx={styles.checkBoxFour}>
                                                <Checkbox
                                                    data-test-id="fifth-checked-box"
                                                    checked={checkBox.isChecked}
                                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleFiveCheckBox(event.target.checked, index)}
                                                    checkedIcon={<Box component={"img"} src={checkedBox} />}
                                                    icon={<Box component={"img"} src={unCheckedBox} />}
                                                />
                                                <Typography sx={styles.shiftText(checkBox.isChecked)}>{checkBox.name}</Typography>
                                            </Box>
                                        ))}
                                    </CheckBoxMainBox>
                                </Box>
                                <Box component={"hr"} style={styles.hrBox} />
                            </Box>
                            <Box>
                                <Box sx={styles.question} >
                                    <Box component={"p"} > {configJSON.sixthQuestion.slice(0, 3)}</Box>
                                    <Box component={"p"}>{configJSON.sixthQuestion.slice(3)}</Box>
                                </Box>
                                <Box className="custom-text-field">
                                    <FormHelperText sx={styles.helperText} data-test-id="sixth-error-message">
                                        {six.sixErrorMessage}
                                    </FormHelperText>
                                    <RadioGroup
                                        data-test-id="sixth-radio-box"
                                        onChange={this.handleSixRadio}
                                        value={six.value}
                                    >
                                        {this.sixData.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                control={<Radio />}
                                                value={option.value}
                                                sx={styles.radioButton(six.value === option.value)}
                                                label={option.label}
                                            />
                                        ))}
                                    </RadioGroup>
                                </Box>
                                <Box component={"hr"} style={styles.hrBox} />
                            </Box>
                            <Button type="submit" className="btn1">{configJSON.continue}</Button>
                        </Box>
                    </ClientDetailBox >
                </Container>
            </ThemeProvider>
        );
    }
}

const styles = {
    checkBoxFour:{ 
        display: "flex", 
        alignItems: "center" 
    },
    radioButton: (isCurrent: boolean) => ({
        "& .MuiTypography-root": {
          fontWeight: isCurrent ? 700 : 400,
          fontSize: "16px",
          color: "#78716C"
        },
        "& .MuiButtonBase-root.MuiRadio-root.Mui-checked": {
          color: "#C7161D"
        },
        "& .MuiButtonBase-root.MuiRadio-root": {
          color: "#A8A29E",
          height: "16px",
          width: "16px",
          bgcolor: "#E7E5E4",
          borderWidth: "1px",
          mr: "5%",
          ml: "4%"
        }
      }),
    seasonMainBox:{ 
        display: "flex", 
        gap: "8px", 
        flexDirection: "column", 
        color: "#78716C" 
    },
    questionMainBox:{ 
        maxWidth: "343px", 
        display: "flex", 
        flexDirection: "column", 
        gap: "16px" 
    },
    firstInputBox: {
        display: "flex",
        gap: "8px",
        alignItems: "center"
    },
    checkBox: {
        width: "16px",
        height: "16px"
    },
    helperText: {
        width: "100%",
        textAlign: "end",
        fontSize: "10px",
        height: "24px",
        fontWeight: 700,
        m: "0px",
        mb: "2%",
        color: "#C7161D"
    },
    question: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "24px",
        borderRadius: "8px",
        color: "#78716C",
        display: "flex",
        textAlign: "left",
        gap: "10px",
        "& span": {
            color: "#C7161D"
        },
        "& p": {
            m: "0px"
        }
    },
    heading:{
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        textAlign: "left",
        [theme.breakpoints.up("lg")]:{
          mt:"80px"
        },
        [theme.breakpoints.up("md")]:{
            mt:"100px"
        }
    },
    shiftText:(isChecked:boolean)=>( { 
        fontWeight: isChecked ? 700 : 400,
        fontSize: "16px",
        color: "#78716C",
        width:"56px",
        whiteSpace: "noWrap"
      }),
    
    passwordTypo2: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        color: "#C7161D",
        display: "flex",
        justifyContent: "space-between",
        whiteSpace: "noWrap"
    },
    hrBox: {
        width: "100%",
        color: "#D6D3D1",
        margin: "2rem 0rem"
    }
};

const CheckBoxMainBox = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    "& > *":{
        width:"50%",
        whiteSpace:"nowrap"
    }
})
const ClientDetailBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "3rem",
    gap: "48px",
    marginBottom:"40px",
    "& .formclass": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "48px",
    },
    "& .errorclass": {
        color: "#C7161D",
        fontFamily: "Quicksand",
        fontSize: "10px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left"
    },
    "& .custom-text-field": {
        width: "343px",
        display: "flex",
        gap: "8px",
        flexDirection: "column"
    },
    "& .btn1": {
        "&.MuiButtonBase-root":{
            color: "White",
            backgroundColor: "#C7161D",
            borderRadius: "8px",
            height: "55px",
            textTransform: "capitalize",
            fontFamily: "Quicksand",
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "22.5px",
            border: "none"
        }
    },
    "& .btn2": {
        color: "#171717",
        border: "1px solid #171717",
        borderRadius: "8px",
        height: "55px",
        textTransform: "capitalize",
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        textAlign: "center",
        backgroundColor: "white"
    },

})

// Customizable Area End
