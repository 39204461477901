Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.surveyApiEndPoint = "bx_block_surveys/surveys"
exports.surveyQuestionApiEndPoint = "bx_block_surveys/questions"
exports.surveyQuestionSubmissionsApiEndPoint = "bx_block_surveys/submissions"
exports.profilePictureOptional="Profile Picture (optional)";
exports.uploadImage="Upload Image";
exports.createClient="bx_block_profile/clients";
exports.applyFilter="Apply Filter";
exports.filters="Filters";
exports.status="Status";
exports.total="Total";
exports.day='Day';
exports.week="Week";
exports.month="Month";
exports.year="Year";
exports.analytics="Analytics";
exports.filter="Filter";
exports.sold="Sold";
exports.pending="Pending";
exports.declined="Declined";
exports.pleaseSelectAFilterOption="Please select a filter option"
exports.surveyHeading="Survey";
exports.requiredField="*required field";
exports.firstQuestion="1. What's the duration of each shift in"
exports.secondQuestion="2. What's the duration of each shift in"
exports.thirdQuestion="3. How many forklift hours per shift?"
exports.fourthQuestion="4. Which months are considered the normal season?"
exports.fifthQuestion="5. Region"
exports.sixthQuestion="6. Do you want quote for:"
exports.shift="Shift"
exports.normalSeason =" NORMAL SEASON "
exports.hightSeason=" HIGH SEASON "
exports.inHours="? (in hours)"
exports.existingTruck="Existing Truck / Replacement Battery / Charger"
exports.newTruck= "New Truck /  New Battery"
exports.continue="Continue"
// Customizable Area End