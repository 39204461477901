import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
  CssBaseline,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  InputAdornment,
  Checkbox
} from "@mui/material";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Calendar from "react-calendar";
import { styled } from "@mui/styles";
import PreviousQuotesController, {
    Props,
    configJSON
} from "./PreviousQuotesController.web";
import { ArrowForwardIos, Close, FilterAltOutlined, Search } from "@mui/icons-material";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import NavigationMenuBar from "../../../../packages/components/src/NavBar.web";
import { Detail } from "react-calendar/dist/cjs/shared/types";
import { 
  mobileBelowImage,
  backgroundImage,
  gradientImage,
  bciLogo,
  checkedBox,
  previousQuotesMobileImg, 
  unCheckedBox
} from "./assets";
const theme = createTheme({
    typography: {
      fontFamily: "Quicksand"
    },
  })
// Customizable Area End

export default class PreviousQuotes extends PreviousQuotesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <ToastContainer />
                <style>
                   {calendarStyles(this.state.currentFilter === "Day" || this.state.currentFilter === "Week")}
                </style>
                {(()=>{
                    const { dateValue, isRangeSelected, isPreviousQuoteScreen,statusOptions } = this.state
                     const calendar = (minDetail: Detail | undefined, maxDetail: Detail | undefined) => {
                        return <Calendar
                          data-test-id="calender"
                          onChange={this.changeDate}
                          value={dateValue}
                          minDetail={minDetail}
                          maxDetail={maxDetail}
                          selectRange={isRangeSelected}
                          maxDate={new Date()}
                          formatShortWeekday={this.handleDays}
                        />
                      }
                {
                 return   isPreviousQuoteScreen?<>
 <NavigationMenuBar navigation={this.props.navigation} id="" />
                <NavigationMenu 
                 navigation={this.props.navigation}
                 id=""
                 srcURL={previousQuotesMobileImg}
                 />
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <MainBox>
                        <Box sx={webStyle.headerBox}>
                            <Typography data-test-id="previous-quote-heading" sx={webStyle.headerTypo}>Previous quotes</Typography>
                        </Box>
                        <Box>
                            <Typography sx={webStyle.typo1}>Review your past quotes at any time. Find the details of your previous requests.</Typography>
                        </Box>
                        <SearchAndFilter >

                            <CustomSearchBox
                                fullWidth
                                data-test-id="btn_change"
                                id="search"
                                name="search"
                                variant="outlined"
                                placeholder="Search"
                                onChange={this.getSearchData}
                                value={this.state.search}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                         <Button
                           data-test-id="filter-button"
                           variant="contained"
                           sx={webStyle.buttontypo}
                           endIcon={<FilterAltOutlined />}
                           onClick={this.handleFilter}
                         >
                           {configJSON.filter}
                         </Button>
                        </SearchAndFilter>
                        <Box style={{ display: "flex", flexDirection: "column" }}>
                         <Box sx={webStyle.optionBox}>
                           {
                             dateValue &&
                             <Box
                               sx={webStyle.filterDetailsButton}
                             >
                               <Typography data-test-id="selected-filter">
                                 {this.handleDateValueSwitch(dateValue)}
                               </Typography>
                               <IconButton data-test-id="remove-date-filter" onClick={this.handleRemoveDateValue}>
                                 <Close />
                               </IconButton>
                             </Box>
                           }
                           {
                             Object.entries(statusOptions).map((status) => (
                               <React.Fragment key={status[0]}>
                                 {
                                   status[1] &&
                                   <Box sx={webStyle.filterDetailsButton}>
                                     <Typography sx={webStyle.optionText} data-test-id="selected-status-value">
                                       {status[0]}
                                     </Typography>
                                     <IconButton data-test-id="remove-status-filter" onClick={() => this.handleStatusCheckBox(!status[1], status[0])}>
                                       <Close />
                                     </IconButton>
                                   </Box>
                                 }
                               </React.Fragment>))
                           }
                         </Box>
                         <hr style={webStyle.hr} />
                         {
                           this.quotesData.map((quote, index: number) => (
                             <Box sx={webStyle.quoteMainBox(quote.status === "edited")} key={index}>
                               <Typography sx={webStyle.statusTypo(this.quoteBgColors[quote.status])}>{quote.status}</Typography>
                               <Box sx={webStyle.quoteContentBox("start")}>
                                 <Typography style={webStyle.CardBoxTypotext1}>{quote.title}</Typography>
                                 <Typography style={webStyle.CardBoxTyponumber1}>{quote.quoteId}</Typography>
                               </Box>
                               <Box sx={webStyle.quoteContentBox("center")}>
                                 <Box>
                                   <Typography style={webStyle.CardBoxTyponumber1}>{quote.to}</Typography>
                                   <Typography style={webStyle.CardBoxTyponumber1} textAlign={"end"}>{quote.date}</Typography>
                                 </Box>
                               </Box>
                               <Box sx={webStyle.quoteContentBox("end")} marginRight={"-15px"}>
                                 <IconButton data-test-id="navigate-to-quote-details" onClick={()=>this.navigateToAnyPage("PreviousQuotesQuoteDetails",index)} style={webStyle.CardBoxTypotext1}><ArrowForwardIos /></IconButton>
                               </Box>
                             </Box>))
                         }
                        </Box>

                    </MainBox>
                </Box>
                    </>: 
                   <Box>
                     <Box sx={styles.imageBox}>
                       <Box component={"img"} src={mobileBelowImage} alt="banner image" sx={styles.mobileBannerImage} />
                       <Box component={"img"} src={bciLogo} sx={styles.logoImage} />
                       <Box component={"img"} src={backgroundImage} sx={styles.bannerImage(0)} />
                       <Box component={"img"} src={gradientImage} sx={styles.bannerImage(1)} />
                       <Box sx={styles.logoBox}>
                         <Box component={"img"} src={bciLogo} />
                         <Typography sx={styles.logoText}>{configJSON.batteryConceptsInternational}</Typography>
                       </Box>
                     </Box>
                     <Box sx={styles.tableBox}>
                       <Typography data-test-id="filters-heading" sx={styles.changeStatusText}>{configJSON.filters}</Typography>
                       <RadioGroup
                         value={this.state.currentFilter}
                         onChange={this.handleStatus}
                         data-test-id="radio-group"
                         sx={styles.radioGroup}
                       >
                         <Box sx={styles.tobBorderBox} />
                         <Typography sx={styles.statusText}>{configJSON.status}</Typography>
                         <Box
                           sx={styles.statusOptionsBox}
                         >{
                             Object.entries(statusOptions).map((status) => (
                               <Box sx={styles.checkBoxMain} key={status[0]}>
                                 <Checkbox
                                   data-test-id="status-check-box"
                                   sx={styles.checkBox}
                                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleStatusCheckBox(event.target.checked, status[0])}
                                   checked={status[1]}
                                   checkedIcon={<Box component={"img"} src={checkedBox} />}
                                   icon={<Box component={"img"} src={unCheckedBox} />}
                                 />
                                 <Typography sx={styles.checkBoxText(status[1])}>{status[0]}</Typography>
                               </Box>))
                           }
                         </Box>
                         <FormControlLabel
                           value={configJSON.day}
                           control={<Radio />}
                           sx={styles.radioButton(this.state.currentFilter === configJSON.day, true)}
                           label={configJSON.day}
                         />
                         <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.day)}>
                           <Box sx={styles.calenderWidthBox(true)}>
                             <Calendar
                               onChange={this.changeDate}
                               value={dateValue}
                               selectRange={isRangeSelected}
                               maxDate={new Date()}
                               formatShortWeekday={this.handleDays}
                             />
                           </Box>
                         </Box>
                         <FormControlLabel
                           value={configJSON.week}
                           control={<Radio />}
                           sx={styles.radioButton(this.state.currentFilter === configJSON.week, false)}
                           label={configJSON.week}
                         />
                         <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.week)}>
                           <Box sx={styles.calenderWidthBox(true)}>
                             {calendar("month", "month")}
                           </Box>
                         </Box>
                         <FormControlLabel
                           value={configJSON.month}
                           control={<Radio />}
                           sx={styles.radioButton(this.state.currentFilter === configJSON.month, false)}
                           label={configJSON.month}
                         />
                         <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.month)}>
                           <Box sx={styles.calenderWidthBox(false)}>
                             {calendar("year", "year")}
                           </Box>
                         </Box>
                         <FormControlLabel
                           value={configJSON.year}
                           control={<Radio />}
                           sx={styles.radioButton(this.state.currentFilter === configJSON.year, false)}
                           label={configJSON.year}
                         />
                         <Box sx={styles.calendarBox(this.state.currentFilter === configJSON.year)}>
                           <Box sx={styles.calenderWidthBox(false)}>
                             {calendar("decade", "decade")}
                           </Box>
                         </Box>
                         <FormControlLabel
                           value={configJSON.total}
                           control={<Radio />}
                           sx={styles.radioButton(this.state.currentFilter === configJSON.Total, true)}
                           label={configJSON.total}
                         />
                         <Box sx={styles.tobBorderBox} />
                       </RadioGroup>
                       <Button
                         onClick={this.handleApplyFilter}
                         data-test-id="apply-filter-button"
                         variant="contained"
                         sx={styles.sendButton}>
                         {configJSON.applyFilter}
                       </Button>
                     </Box>
                   </Box>
                }
            })()}
            </ThemeProvider> 
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const calendarStyles=(isSquareShape:boolean)=>(`
.react-calendar {
  width: 100%;
  background: #F5F5F5;
  line-height: 1.125em;
}
.react-calendar * {
  font-family: 'Quicksand';
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  margin-bottom: 0.3rem;
  padding: 0.1rem;
  width: 50px;
  height: 45px;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
}
.react-calendar__navigation button {
  min-width: 40px;
  background: none;
}
.react-calendar__navigation__label__labelText {
  font-weight: 700;
  font-size: 16px;
  color: #171717;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  text-transform: capitalize;
}
.react-calendar__month-view__weekdays__weekday > abbr{
  color: #B4B4B4;
  width: 5px;
  overflow: hidden;
  text-decoration: none;
}
.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: #dad7d77e;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 0.8rem;
}
.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
  color: #e6e6e6;
} 

.react-calendar__tile--active {
  background-color: #de8589;
  color: #171717;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #de8589;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:hover {
  color:#FFFFFF;
  font-weight: 700;
  font-size: 12px;
  background-color: #C7161D;
  border-top-left-radius: 20%;
  border-bottom-left-radius: 20%;
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:hover {
  background-color: #de8589;
  color:#FFFFFF;
  font-weight: 700;
  font-size: 12px;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
}
.react-calendar__tile--rangeStart>abbr {
  border-radius: 20%;
  background-color: #C7161D;
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  text-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#FFFFFF
}
.react-calendar__tile--rangeEnd>abbr {
  border-radius: 20%;
  background-color: #C7161D;
  height: 100%;
  width: 100%;
  aspect-ratio: 1/1;
  text-align: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  color:#FFFFFF
}
.react-calendar__tile--hoverStart {
  border-top-left-radius: 20%;
  border-bottom-left-radius: 20%;
}
.react-calendar__tile--hoverEnd {
  background-color: #B4B4B4;
  border-top-right-radius: 20%;
  border-bottom-right-radius: 20%;
}
.react-calendar__month-view__weekdays{
    border-bottom: 1px solid #E5E0EB;
}
.react-calendar__tile--rangeBothEnds {
  background-color: #C7161D;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-calendar__tile--rangeEnd, .react-calendar__tile--rangeEnd{
border-top-right-radius: 20%;
}
.react-calendar__tile--hoverBothEnds {
background-color: #de8589;
display: flex;
justify-content: center;
align-items: center;
}
.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--hoverStart {
  background-color: #de8589;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}
.react-calendar__tile--active.react-calendar__tile--rangeEnd.react-calendar__tile--hoverEnd {
  background-color: #de8589;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}
.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  font-weight: 500;
  font-size: 25px;
}
.react-calendar__month-view__days .react-calendar__tile--active:nth-child(7n),
.react-calendar__month-view__days .react-calendar__tile--hover:nth-child(7n) {
background-color: #de8589;
}
.react-calendar__month-view__days .react-calendar__tile--active:nth-child(7n+1),
.react-calendar__month-view__days .react-calendar__tile--hover:nth-child(7n+1) {
background-color: #de8589;
}
.react-calendar__tile:disabled {
  color: #B4B4B4;
}
.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    border:0.63px solid #A8A29E;
    display: flex;
    flex:0 0 31.333% !important;
    justify-content: center;
    align-items: center;
    margin: 1.5%;
    font-weight: 400;
    font-size: 12px;
}
.react-calendar__year-view .react-calendar__tile:disabled, .react-calendar__decade-view .react-calendar__tile:disabled {
  border-color: transparent;
}
.react-calendar__navigation button:enabled, .react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  color:#C7161D;
  background-color: none;
  background: none;
}
.react-calendar__tile{
 font-size: 14px;
 font-weight: 400;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeStart:hover {
  color:#FFFFFF;
  font-weight: 700;background-color:${!isSquareShape? "#C7161D":"#de8589"}; 
  border-top-left-radius:${!isSquareShape? "0%":"20%"};
  border-bottom-left-radius:${!isSquareShape? "0%":"20%"};
}
.react-calendar__tile--rangeEnd,
.react-calendar__tile--rangeEnd:hover {
  background-color:${!isSquareShape? "#C7161D":"#de8589"}; 
  color:#FFFFFF;
  font-weight: 700;
  font-size: 12px;
   border-top-right-radius: ${!isSquareShape? "0%":"20%"};
  border-bottom-right-radius: ${!isSquareShape? "0%":"20%"};
}
`)
const styles={
  statusText:{
    mt:"3%",
    fontWeight:700,
    color:"#78716C"
  },
  statusOptionsBox:{
    height:"60px",
    overflow: "hidden",
    transition: "0.5s ease",
    width: "100%",
    borderBottom: "1px solid #D6D3D1",
    display: "flex",
    flexDirection: "wrap",
   },
   checkBoxMain:{ 
    display: "flex",
    alignItems:"center",
    gap:"16px",
    mr:"10%"
  },
  checkBox: {
    width: "16px",
    height: "16px"
  },
  checkBoxText:(isChecked:boolean)=>({
    fontWeight:isChecked?700:400,
    textTransform:"capitalize",
    fontSize:"14px",
    color:"#171717"
  }),
  radioGroup:{
    "& .MuiFormControlLabel-root":{
      ml:"-22px"
    }
  },
 
  calenderWidthBox:(isMobile:boolean)=>({
    width:isMobile?"300px":"100%"
  }),
  tobBorderBox:{ 
    width: "100%", 
    height: "1px", 
    bgcolor: "#D6D3D1" 
  },
  statusBox:(isCurrent:boolean)=>({ 
    height: isCurrent ? "340px" : 0, 
    overflow: "hidden", 
    transition: "0.5s ease", 
    width: "100%",
    borderBottom: "1px solid #D6D3D1",
    display: "flex",
    flexDirection: "wrap",
    alignItems: "center",
    justifyContent: "center",
     
  }),
  calendarBox:(isCurrent:boolean)=>({ 
    height: isCurrent ? "360px" : 0, 
    overflow: "hidden", 
    transition: "0.5s ease", 
    width: "100%",
    borderBottom: "1px solid #D6D3D1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    bgcolor:"#F5F5F5"
  }),
  sendButton: {
    my: "5%",
    "&.MuiButtonBase-root.MuiButton-root": {
      bgcolor: "#C7161D",
      width: "100%",
      height: "56px",
      borderRadius: "8px",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "18px",
      color: "#FFFFFF"
    }
  },
  radioButton: (isCurrent: boolean,isFirstBox:boolean) => ({
     py: "3%",
    "& .MuiTypography-root": {
      fontWeight: isCurrent ? 700 : 400,
      fontSize: "16px",
      color: "#78716C"
    },
    "& .MuiButtonBase-root.MuiRadio-root.Mui-checked": {
      color: "#C7161D"
    },
    "& .MuiButtonBase-root.MuiRadio-root": {
      color: "#A8A29E",
      height: "16px",
      width: "16px",
      bgcolor: "#E7E5E4",
      borderWidth: "1px",
      mr: "3%",
      ml: "4%"
    }
  }),
  changeStatusText:{
    fontSize:"32px",
    fontWeight:700,
    color:"#171717",
    mt:"8%",
    mb:"6%",
    "& + .MuiFormGroup-root":{
      mb:"5%"
    }
  },
  tableBox: {
    mx: "auto",
    [theme.breakpoints.up("md")]: {
      width: "600px"
    },
    [theme.breakpoints.up("xs")]: {
      width: "343px"
    }
  },
  imageBox: {
    position: "relative",
    width: "100%",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileBannerImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.up("xs")]: {
      objectPosition: "top",
      display: "block",
      borderRadius: "25px 25px 0px 0px",
      overflow: "hidden",
    },
  },
  logoBox: {
    position: "absolute",
    zIndex: 2,
     display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  },
  logoText: {
    color: "#FFFFFF",
    fontWeight: 700,
    fontSize: "18px",
    textAlign: "center",
   },
   bannerImage: (zIndexNumber: number) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: zIndexNumber,
    borderRadius: "25px",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("xs")]: {
      display: "none",
    },
  }),
  logoImage: {
    height: "40px",
    width: "90px",
    position: "absolute",
    zIndex: 2,
    left: "50%",
    transform: 'translate(-50%, -150%)',
    [theme.breakpoints.up("md")]: {
      objectPosition: "top",
      display: "none",
    },
    [theme.breakpoints.between("xs", "md")]: {
      display: "block",
    },
  },
}
const webStyle = {
  hr:{ 
    color: "#D6D3D1", 
    width: "100%" 
  },
  quoteMainBox:(isEdit:boolean)=>({ 
    width: "100%", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "space-between", 
    bgcolor:isEdit?"#78716C":"#171717", 
    height: "80px", 
    borderRadius: "8px",
    p:2,
    position:"relative",
    overflow:"hidden",
    mb:"2.3%"
   }),
  statusTypo:(bgColor:string)=>({
    textTransform:"capitalize",
    position:"absolute",
    right:"0px",
    top:"0px",
    borderRadius:"0px 0px 0px 4px",
    p:"3px 15px",
    bgcolor:bgColor,
    fontSize:"10px",
    fontWeight:700,
    color:"#FFFFFF"
  }),
  quoteContentBox:(itemsValue:string)=>({
    width:"33.33%", 
    display: "flex", 
    flexDirection: "column", 
    alignItems:itemsValue,
    gap: "5px"
  }),
  optionBox:{ 
    display: "flex", 
    flexWrap: "wrap", 
    alignItems: "start", 
    justifyContent:"start",
    gap: "4px", 
    mt:"1.5%",
    mb: "1.5%" 
  },
  optionText:{
    fontSize: "12px",
    fontWeight: 700,
    color: "#171717",
    textTransform: "capitalize"
  },
  filterDetailsButton: {
    height: "32px",
    borderRadius: "4px",
    bgcolor: "#E7E5E4",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "fit-content",
    pl: "10px",
    "& > * ": {
      fontSize: "12px",
      fontWeight: 700,
      color: "#171717",
    }
  },
    headerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    headerTypo: {
        fontFamily: "Quicksand",
        fontSize: "32px",
        fontWeight: 700,
        lineHeight: "40px",
        textalign: "left",
        mt:"10%"
    },
    buttontypo: {
      "&.MuiButtonBase-root":{
      textTransform: "capitalize",
      fontSize: "12px",
      fontWeight: 700,
      lineHeight: "24px",
      width:"82px",
      boxShadow:0,
      borderRadius:"4px",
      bgcolor: "#E7E5E4",
      color: "#171717"}
    },
    typo1: {
        fontFamily: "Quicksand",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "17.5px",
        color: "rgba(120, 113, 108, 1)",
        my:"4%"
    },
    CardBoxTypotext1: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        color: "white"
    },
    CardBoxTyponumber1: {
        fontFamily: "Quicksand",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "15px",
        color: "white",
    },
    CardNumberTypo: {
        fontFamily: "Quicksand",
        fontSize: "40px",
        fontWeight: 700,
        lineHeight: "50px",
        color: "rgba(255, 255, 255, 1)"
    },
    CardTextTypo: {
        fontFamily: "Quicksand",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "22.5px",
        color: "rgba(255, 255, 255, 1)",
        textTransform: "capitalize",
        width: "100%"
    },
    typoBox: {
        maxHeight: "100px",
        display: "flex",
        marginLeft: "10px",
        alignItems: "end",
        width: "50%"
    },
    imgBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "130px",
        height: "100px",
    }
};

const MainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    padding: "0.8rem"
});
const SearchAndFilter = styled(Box)({
    display: "flex",
    width: "100%",
    gap: "5px",
    "@media (max-width:960px)": {
        display: "flex",
    },
    "@media (min-width:720px)": {
    },
});
 
const CustomSearchBox = styled(TextField)({
    background: "rgba(231, 229, 228, 1)",
    fontFamily: "Quicksand",
    borderRadius:"4px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important"
    },
    "& .MuiInputBase-root": {
      height:"48px",
      fontFamily: 'Quicksand',
    },
    "@media (max-width:960px)": {
    },
    "@media (min-width:720px)": {
    },
});

// Customizable Area End
